import Api from '../../../common/services/api';

export const SOCIAL_VOTE_POLL = 'social/VOTE_POLL';

export default (pollId, optionId, messageId, currentVoteResult) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  Api.post(`/v2/networks/${selected}/polls/${pollId}/vote`, { option_ids: [optionId] });

  return dispatch({
    type: SOCIAL_VOTE_POLL,
    pollId,
    optionIds: [optionId],
    messageId,
    currentVoteResult,
  });
};
