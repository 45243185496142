import * as fileUtil from '@common/utils/file';

export const validatePoll = (poll) => {
  const validOptions = poll.options.filter(({ value }) => !!value);
  return poll.question !== '' && validOptions.length > 1;
};

export const validateAttachments = (attachments) => {
  return !fileUtil.isProcessingAttachments(attachments);
};
