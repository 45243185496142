import * as R from 'ramda';
import Api from '@common/services/api';
import { ETimelineTypes } from '../definitions';

export const SOCIAL_POST_MESSAGE = 'social/POST_MESSAGE';

export default (values, timeline, shouldAppend) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();
  const { poll } = values;

  let endpoint = '';

  if (timeline.type === ETimelineTypes.NETWORK) {
    endpoint = `/v3/networks/${timeline.parent.id}/messages`;
  } else if (timeline.type === ETimelineTypes.TEAM) {
    endpoint = `/v3/teams/${timeline.parent.id}/messages`;
  } else if (timeline.type === ETimelineTypes.CHANNEL) {
    endpoint = `/v1/organisations/${selected.id}/channels/${timeline.parent.id}/messages`;
  } else if (timeline.type === ETimelineTypes.EVENT) {
    endpoint = `/v1/organisations/${selected.id}/events/${timeline.parent.id}/messages`;
  }

  const payload = {
    text: values.text,
    files: R.pluck('id', values.attachments),
    publish_at: values.publish_at ? values.publish_at.toISOString() : null,
    is_important: !!values.is_important,
    can_comment: !!values.can_comment,
  };

  if (poll) {
    payload.poll_question = poll.question;
    payload.poll_options = R.pipe(R.pluck('value'), R.reject(R.isEmpty))(poll.options);
  }

  const { data: message } = await Api.post(endpoint, payload);

  dispatch({
    type: SOCIAL_POST_MESSAGE,
    append: shouldAppend,
    scheduled: !!payload.publish_at,
    message,
  });
};
